<template>
  <div>
    <div class="d-flex justify-content-between">
      <div>
        <DatePicker
          range
          v-model="dateRange"
          @confirm="filterApplied"
          @change="filterCancelled"
          confirm
        />
      </div>

      <div class="right-align m-2">
        <div v-if="allReportsLoading">
          <i class="fa fa-spinner fa-spin fa-2x fa-fw"></i>
        </div>
        <div v-else>
          <export-excel
            ref="myBtn"
            class="btn btn-primary"
            :fetch="fetchallReports"
            :fields="json_fields"
            worksheet="My Worksheet"
            name="NomadicBookingReports.xls"
          >
            Download Report
          </export-excel>
        </div>
      </div>
    </div>
    <div></div>
    <b-table
      striped
      hover
      :fields="fields"
      :busy="isLoading"
      :responsive="true"
      :items="items"
      @row-clicked="onRowClicked"
    >
      <template #cell(createdAt)="data">
        <span>
          {{ moment(data.item.createdAt).format("LL") }}
        </span>
      </template>
      <template #cell(updatedAt)="data">
        <span>
          {{ moment(data.item.updatedAt).format("LL") }}
        </span>
      </template>
      <template #cell(type)="data">
        <span>
          {{
            data.item.type.charAt(0).toUpperCase() +
            data.item.type.slice(1).replaceAll("_", " ")
          }}
        </span>
      </template>
      <template #cell(program_id)="data">
        <span>
          <router-link :to="`program/details?id=${data.item.program_id._id}`">
            {{ data.item.program_id.name }}
          </router-link>
        </span>
      </template>

      <template #table-busy>
        <div class="text-center">
          <b-spinner type="grow" variant="primary"></b-spinner>
        </div>
      </template>
    </b-table>
    <b-pagination
      pills
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
    >
    </b-pagination>
  </div>
</template>
  
  <script>
import { discover } from "@/config/api/discover";
import DatePicker from "vue2-datepicker";

export default {
  components: { DatePicker },
  mounted() {
    this.getReports();
  },
  watch: {
    currentPage(val) {
      this.currentPage = val;
      this.getReports();
    },
  },
  data() {
    return {
      items: [],
      dateRange: null,
      json_data: [],
      currentPage: 1,
      rows: 2,
      startDate: null,
      endDate: null,
      perPage: 10,
      json_fields: {
        "Booking Date": "updatedAt",
        Title: "title",
      },
      fields: [
        { key: "title", label: "Title" },
        { key: "type", label: "Type" },

        { key: "createdAt", label: "Created On" },
        { key: "updatedAt", label: "Updated Date" },
      ],
      isLoading: false,
      allReportsLoading: false,
      reportsGenerated: false,
    };
  },

  methods: {
    onRowClicked(item) {
      this.$router.push({
        path: "/edit_discover/",
        query: { id: item._id },
      });
    },
    filterApplied() {
      this.startDate = this.dateRange[0].toISOString().split("T")[0];
      this.endDate = this.dateRange[1].toISOString().split("T")[0];
      this.getReports();

      // let params = {
      //   startDate: startDate,
      //   endDate: endDate,
      // };
      // this.setDates(params);
      // if (startDate && endDate) {
      //   this.isFilter = true;
      //   this.filtringTable(params).then(() => {
      //     this.isFilter = false;
      //   });
      // }
    },
    filterCancelled() {
      if (this.dateRange[0] == null) {
        this.startDate = null;
        this.endDate = null;
        this.getReports();
      }
    },
    async getReports() {
      this.isLoading = true;
      this.tableData = [];
      const api = discover.getDiscoverForAdmin;
      api.params = { page: this.currentPage, perPage: 10 };
      if (this.startDate != null && this.endDate != null) {
        api.params.fromDate = this.startDate;
        api.params.toDate = this.endDate;
      }
      await this.generateAPI(api)
        .then((res) => {
          this.items = res.data.data;

          this.rows = res.data.total_no_of_documents;
          // this.perPage = res.data.current_data_count;

          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
          console.log("Loaded");
        });
    },
    async fetchallReports() {
      this.allReportsLoading = true;
      const api = discover.getDiscoverForAdmin;
      api.params = { all: 1 };
      if (this.startDate != null && this.endDate != null) {
        api.params.fromDate = this.startDate;
        api.params.toDate = this.endDate;
      }
      var res = await this.generateAPI(api);

      this.reportsGenerated = true;
      this.allReportsLoading = false;
      if (res.data.data.length > 0) {
        return res.data.data;
      } else {
        alert("No data found");
        return [];
      }
    },
  },
};
</script>
  
  <style></style>
  