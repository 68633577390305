export const discover = {
    getDiscoverForAdmin: {
        url: "/v1/admin/discover",
        method: "GET",
        params: null,
    },

    updateDiscoverForAdmin: {
        url: "/v1/admin/discover",
        method: "POST",
        params: null,
    }

}